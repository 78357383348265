.footer {
  background-color: var(--primary-dark);
  display: flex;
  justify-content: center;
  padding-top: var(--space-md);
  grid-area: footer;

  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .footer-text {
      font-size: 0.9rem;
      font-weight: 700;
      display: block;
      text-transform: uppercase;
      color: white;
      margin-bottom: var(--space-sm);
      letter-spacing: 1.3125px;
    }

    .logo {
      width: 160px;
      margin-bottom: var(--space-sm);
    }
  }
}
