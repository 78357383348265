@import '../styles/_config.scss';
@import '../styles/_utilities.scss';

h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
}

h1 {
    font-size: 2.4rem;

    @include respond-above(xs) {
        font-size: 3rem;
    }
}

h2 {
    font-size: 1.6rem;

    @include respond-above(xs) {
        font-size: 1.8rem;
    }
}

h3, %h3 {
    font-size: 1.2rem;
}

h4, %h4 {
    font-size: 0.875rem;
    @include respond-above(xs) {
        font-size: 1rem;
    }
}

p {
    font-size: 1rem;
    line-height: 1.5;
}

p + p {
    margin-top: var(--space-sm);
}