:root {
  // colors
  --ui-background: #ebeef7;
  --primary-dark: #1f356e;
  --primary-light: #2957cc;
  --text-default: #1f356e;
  --text-dark: #1f356e;
  --graph-bar-dark: #1f356e;
  --text-link: #1448cc;
  --secondary-default: #fda533;
  --secondary-dark: #f29513;
  --ui-gray-lighter: #f7faff;
  --ui-gray: #ebeef5;
  --ui-gray-dark: #d7ddea;
  --ui-gray-darker: #dadde2;
  --ui-blue: #e5edff;
  --ui-blue-dark: #dde4f7;
  --table-line: rgba(31, 53, 110, 0.2);
  --metric: #3251a6;

  [data-theme="dark"] {
    --ui-background: #192c59;
    --primary-dark: #1f356e;
    --primary-light: #2662f9;
    --graph-bar-dark: #2662f9;
    --text-default: #ffffff;
    --text-dark: #1f356e;
    --text-link: #fd940a;
    --secondary-default: #fd940a;
    --secondary-dark: #e28707;
    --ui-gray-lighter: #2a468c;
    --ui-gray: #1f366b;
    --ui-gray-dark: #192c59;
    --ui-gray-darker: #dadde2;
    --ui-blue: #192c59;
    --ui-blue-dark: #16264e;
    --table-line: rgba(31, 53, 110, 1);
  }

  // layout
  --max-width: 1920px;
  --box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  --box-shadow-sm: 0px 2px 4px rgba(22, 38, 77, 0.2);
  --box-shadow-light: 0px 2px 4px rgba(22, 38, 77, 0.1);
  [data-theme="dark"] {
    --box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    --box-shadow-sm: 0px 2px 4px rgba(22, 38, 77, 0.4);
    --box-shadow-light: 0px 2px 4px rgba(22, 38, 77, 0.3);
  }

  // spacing
  --space-xs: 0.5rem;
  --space-sm: 1rem;
  --space-md: 2rem;
  --space-lg: 4rem;
  --space-xl: 6rem;
  --space-page: 8rem;

  // borders
  --border-radius-sm: 0.25rem;
  --border-radius-md: 0.5rem;
  --border-radius-lg: 1rem;

  // CSS breakpoint values
  --screen-xs: 576px;
  --screen-sm: 768px;
  --screen-md: 992px;
  --screen-lg: 1200px;
  --screen-xl: 1440px;
}

// breakpoints
$breakpoints: (
  "xs": 576px,
  "sm": 768px,
  "md": 992px,
  "lg": 1200px,
  "xl": 1440px,
);

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
