.login {
  min-height: 100%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-content {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--space-md);

      .logo-wrapper {
        width: 33%;
        margin-bottom: var(--space-md);
      }

      .input-container {
        margin-bottom: var(--space-md);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
}