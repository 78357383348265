.testimony-profile-pic {
  width: 10rem;
  border-radius: 50%;
}

textarea[name="testimony-body"] {
  height: 7rem;
}

.add-video-instructions {
  padding: 1rem 0;
  ol {
    margin-left: 1rem;
  }
}
