.notification-list {
  position: fixed;
  bottom: var(--space-md);
  right: var(--space-md);
  width: 350px;

  display: flex;
  flex-direction: column-reverse;

  .notification {
    color: var(--text-dark);
    width: 100%;
    background-color: white;
    border-radius: var(--border-radius-sm);
    border-top: 4px solid var(--secondary-default);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    margin-top: var(--space-sm);
    overflow: hidden;

    .text-container {
      padding: var(--space-sm);
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      
      * {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: var(--space-xs);

        &:last-child {
          margin-right: 0;
        }
      }

      :first-child {
        flex: 1;
      }

      .action {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      svg {
        cursor: pointer;
        transition: .2s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .duration {
      grid-area: duration;
      height: 4px;
      
      .remaining {
        width: 50%;
        height: 100%;
        background-color: var(--ui-gray-darker);
      }
    }
  } 
}