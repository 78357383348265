@import '../../styles/_config.scss';

.layout {
	min-height: 100vh;
	margin: 0 auto;
	display: block;

	@include respond-above(lg) {
		display: grid;
		grid-template-columns: 1fr 7fr;
		grid-template-rows: 7fr 160px;
		grid-template-areas:
			'sidebar page'
			'footer footer';
	}

	.staging-tag {
		position: fixed;
    bottom: 0px;
    right: 0px;
    padding: 0.5rem 1rem;
    color: white;
    background-color: rgb(181, 42, 42);
    border-top-left-radius: 0.25rem;
    font-weight: bold;
	}
}

.page {
	grid-area: page;
	width: 100%;
	max-width: var(--max-width);
	padding: var(--space-page) var(--space-md) var(--space-lg);
	min-height: 100vh;
	// margin-top: var(--space-md);

	@include respond-above(xs) {
		padding-left: var(--space-md);
		padding-right: var(--space-md);
	}

	@include respond-above(sm) {
		padding: var(--space-page) var(--space-lg);
	}

	@include respond-above(lg) {
		padding: var(--space-xl) var(--space-xl) var(--space-page);
		margin-top: 0;
	}

	.page__header {
		grid-area: header;
		margin-bottom: var(--space-md);

		@include respond-above(xs) {
			margin-bottom: var(--space-lg);
		}

		.navigation {
			display: flex;
			align-items: center;
			margin-bottom: var(--space-sm);

			> * + * {
				margin-left: 0.75rem;
			}

			@include respond-above(xs) {
				margin-bottom: var(--space-md);
			}
		}
	}

	.page__body {
		display: grid;
		gap: var(--space-lg);

		.page__section {
			display: grid;
			gap: var(--space-md);

			@include respond-above(xs) {
				gap: var(--space-lg);
			}

			@include respond-above(lg) {
				&.grid--1-1 {
					grid-template-columns: 1fr 1fr;
				}
				&.grid--2-1 {
					grid-template-columns: 2fr 1fr;
				}
				&.grid--1-2 {
					grid-template-columns: 1fr 2fr;
				}
			}

			.page__subsection {
				display: grid;
				gap: var(--space-md);
				//grid-auto-rows: minmax(min-content, max-content);

				@include respond-above(xs) {
					gap: var(--space-lg);
				}
			}
		}
	}
}
