$line-width: 3px;
$animation-time: 3s;
$animation-timing: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$dot-width: 50px;

.spinner-container {
  width: 150px;
  height: 150px;
  position: relative;

  .dot {
    position: absolute;
    width: $dot-width;
    height: $dot-width;
    border-radius: 50%;
    background-color: #2957cc;

    &.first {
      top: $dot-width;
      left: $dot-width;
      animation: $animation-time $animation-timing 0s infinite
        center-pulse-first alternate;
    }
    &.second {
      top: $dot-width;
      right: $dot-width;
      animation: $animation-time $animation-timing 0s infinite
        center-pulse-second alternate;
    }
    &.third {
      bottom: $dot-width;
      right: $dot-width;
      animation: $animation-time $animation-timing 0s infinite
        center-pulse-third alternate;
    }
    &.fourth {
      bottom: $dot-width;
      left: $dot-width;
      background-color: #fda533;
      z-index: 5;
      animation: $animation-time $animation-timing 0s infinite
        center-pulse-fourth alternate;
    }
  }

  .line {
    position: absolute;
    background-color: #2957cc;
    bottom: 25px;
    left: 25px;

    &.horizontal {
      height: $line-width;
      width: 100px;
      animation: $animation-time $animation-timing 0s infinite draw-horizontal
        alternate;
    }
    &.vertical {
      width: $line-width;
      height: 100px;
      animation: $animation-time $animation-timing 0s infinite draw-vertical
        alternate;
    }
    &.diagonal {
      width: $line-width;
      height: 125px;
      transform: rotate(45deg);
      transform-origin: right bottom;
      animation: $animation-time $animation-timing 0s infinite draw-diagonal
        alternate;
    }
  }
}

@keyframes center-pulse-first {
  0%,
  10% {
    top: $dot-width;
    left: $dot-width;
  }
  25%,
  100% {
    top: 0;
    left: 0;
  }
}

@keyframes center-pulse-second {
  0%,
  25% {
    top: $dot-width;
    right: $dot-width;
  }
  40%,
  100% {
    top: 0;
    right: 0;
  }
}

@keyframes center-pulse-third {
  0%,
  40% {
    bottom: $dot-width;
    right: $dot-width;
  }
  55%,
  100% {
    bottom: 0;
    right: 0;
  }
}

@keyframes center-pulse-fourth {
  0%,
  55% {
    bottom: $dot-width;
    left: $dot-width;
  }
  70%,
  100% {
    bottom: 0;
    left: 0;
  }
}

@keyframes draw-vertical {
  0%,
  70% {
    height: 0;
  }
  80% {
    height: 100px;
  }
}

@keyframes draw-horizontal {
  0%,
  70% {
    width: 0;
  }
  80% {
    width: 100px;
  }
}

@keyframes draw-diagonal {
  0%,
  70% {
    height: 0;
  }
  80% {
    height: 125px;
  }
}
